import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-loading-wrapper',
  templateUrl: './loading-wrapper.component.html',
  styleUrls: ['./loading-wrapper.component.scss']
})
export class LoadingWrapperComponent implements OnInit {

  showSpinner = true;
  @Input() public readonly message: string = 'Loading...';

  constructor() { }

  ngOnInit(): void {
  }

}
