import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-loading-wrapper-overlay',
  templateUrl: './loading-wrapper-overlay.component.html',
  styleUrls: ['./loading-wrapper-overlay.component.scss']
})
export class LoadingWrapperOverlayComponent implements OnInit {
  @Input() public message: string;

  constructor() { }

  ngOnInit(): void {
  }

}
